function capitalizeInitials(str, separator) {
  return str
    ?.split(separator)
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(" ");
}

export function capitalizeInitialsv2(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeInitialsv3(str, separator) {
  return str
    .split(separator)
    .map((item) => item.toUpperCase())
    .join(" ");
}

export function capitalizeInitialsv4(str, separator) {
  return str
    .split(separator)
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(" ");
}

export default capitalizeInitials;
