import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import DashboardIcon from "../../assets/svg/dashboardIcon";
import CompanyIcon from "../../assets/svg/companyIcon";
import UserIcon from "../../assets/svg/userIcon";
import ViolationIcon from "../../assets/svg/violationIcon";
import EmailIcon from "../../assets/svg/emailIcon";
import LetterIcon from "../../assets/svg/letterIcon";
import CloseIcon from "../../assets/svg/closeIcon";
import LogoutIcon from "../../assets/svg/logoutIcon";
import ProCamLogo from "../../assets/svg/proCamLogo";
import AttorniesIcon from "../../assets/svg/attorniesIcon";
import PropertiesIcon from "../../assets/svg/propertiesIcon";
import CommunitiesIcon from "../../assets/svg/communitiesIcon";
import JobIcon from "../../assets/svg/jobIcon";
import EditIcon from "../../assets/svg/editIcon";
import ViolationList from "../../assets/svg/violationList";
import LetterLevelsIcon from "../../assets/svg/letterLevelsIcon";
import DocumnetLibraryIcon from "../../assets/svg/documentLibraryIcon";
import ReportIcon from "../../assets/svg/reportIcon";

import { logout } from "../../redux/features/auth-slice";
import { logout as adminLogout } from "../../redux/features/masterAuthSlice";
import List from "../ListComponent/ListComponent";

export default function Sidebar({ toggleSidebar, setToggle }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const adminAuth = useSelector((state) => state.adminAuth);
  const auth = useSelector((state) => state.auth);

  const communityId = useSelector((store) => store.community.communityId);
  const [activeItem, setActiveItem] = useState(0);

  const master_admin = [
    {
      link: "/dashboard",
      text: "Dashboard",
      icon: DashboardIcon,
    },
    {
      link: "/companies",
      text: "Companies",
      icon: CompanyIcon,
    },
    {
      link: "/user",
      text: "Users",
      icon: UserIcon,
    },

    {
      link: "/violation-library",
      text: "ProCAM Violation Library",
      icon: ViolationIcon,
    },
    {
      link: "/letter-library",
      text: "ProCAM Letter Library",
      icon: LetterIcon,
    },
    {
      link: "https://login.sendgrid.com/login",
      text: "Email Templater",
      icon: EmailIcon,
      newWindow: true,
    },
    {
      link: "/security-permission",
      text: "User Security Permissions",
      icon: UserIcon,
    },
    {
      link: "/report",
      text: "Reports",
      icon: ReportIcon,
    },
    {
      link: "/help",
      text: "User Support",
      icon: UserIcon,
    },
  ];

  const company_admin = {
    sidebar: [
      {
        link: "/companydashboard",
        text: "Dashboard",
        icon: DashboardIcon,
      },
      {
        link: "/company/jobs",
        text: "Jobs",
        icon: JobIcon,
      },
      {
        link: {},
        text: "Community Inspection",
        icon: CommunitiesIcon,
      },
      {
        link: "/company/communities",
        text: "Communities",
        icon: CommunitiesIcon,
      },
      {
        link: "",
        text: "Reports",
        icon: ViolationIcon,
      },
      {
        link: "/company/properties",
        text: "Properties",
        icon: PropertiesIcon,
      },
    ],
    settings: [
      {
        link: "/user-company",
        text: "Users",
        icon: UserIcon,
      },
      {
        link: "/company/manage-role",
        text: "Manage User Roles",
        icon: EditIcon,
      },
      {
        link: "/company/violation-library",
        text: "Master Violation Library",
        icon: ViolationIcon,
      },
      {
        link: "/company/letter-library",
        text: "Master Letter Library",
        icon: LetterIcon,
      },
      {
        link: "/company/attornies",
        text: "Attorney List",
        icon: AttorniesIcon,
      },
      {
        link: "/company/help",
        text: "Help",
        icon: UserIcon,
      },
      {
        link: "/company/user-profile",
        text: "My Profile",
        icon: UserIcon,
      },
    ],
  };

  const community_admin = {
    sidebar: [
      {
        link: `/community/dashboard/${communityId}`,
        text: "Dashboard",
        icon: DashboardIcon,
      },
      {
        link: `/community/jobs/${communityId}`,
        text: "Jobs",
        icon: JobIcon,
      },
      {
        link: `/community/${communityId}/properties`,
        text: "Properties",
        icon: PropertiesIcon,
      },
    ],
    settings: [
      {
        link: `/community/${communityId}/edit`,
        text: "Edit Community",
        icon: EditIcon,
      },
      {
        link: `/community/${communityId}/user`,
        text: "Users",
        icon: UserIcon,
      },
      {
        link: `/community/violation-library/${communityId}`,
        text: "Violation List",
        icon: ViolationList,
      },
      {
        link: `/community/${communityId}/letter-level`,
        text: "Letter Levels",
        icon: LetterLevelsIcon,
      },
      {
        link: `/community/letter-library/${communityId}`,
        text: "Letter Library",
        icon: LetterIcon,
      },
      {
        link: `/community/${communityId}/document-library`,
        text: "Document Library",
        icon: DocumnetLibraryIcon,
      },
      {
        link: "/companydashboard",
        text: "Company Dashboard",
        icon: DashboardIcon,
      },
      {
        link: "/company/user-profile",
        text: "My Profile",
        icon: UserIcon,
      },
    ],
  };

  return (
    <div
      className={`fixed z-40 top-2 bottom-2 left-4 pt-1 pb-2 px-4 justify-between rounded-2xl bg-gradient-to-b from-secondary-teal to-tertiary-teal flex-col sidebar-animation
      ${toggleSidebar ? "sidebar-open" : ""}`}
    >
      <div className="relative h-[100%] flex flex-col">
        <div className="flex justify-between items-center">
          <div
            onClick={() => setToggle(!toggleSidebar)}
            className="bg-primary-white flex justify-center items-center h-[2rem] w-[2rem] rounded-full cursor-pointer ml-[1rem]"
          >
            <CloseIcon className="w-[1.4rem] h-[1.4rem] text-primary-teal" />
          </div>
          <ProCamLogo className="w-[6rem] text-primary-white" />
        </div>

        <div className="sidebar_custom_class grow flex flex-col">
          {/* master Admin dashboard  */}
          {adminAuth.token &&
            !location.pathname.includes("company") &&
            !location.pathname.includes("community") && (
              <>
                <ul className={"flex flex-col gap-2 px-4 py-6"}>
                  {master_admin.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setActiveItem(index);
                          if (item.newWindow) {
                            window.open(item.link, "_blank");
                          }
                          setToggle(!toggleSidebar);
                        }}
                      >
                        <Link to={item.newWindow ? null : item.link}>
                          <List
                            isActive={activeItem === index ? true : false}
                            Icon={item.icon}
                            toggleSidebar={toggleSidebar}
                            text={`${toggleSidebar ? `${item.text}` : ""}`}
                          />
                        </Link>
                      </div>
                    );
                  })}
                </ul>

                <div
                  className="flex items-center cursor-pointer mt-auto px-8 pb-2"
                  onClick={() => {
                    dispatch(adminLogout());
                    setToggle(!toggleSidebar);
                  }}
                >
                  <LogoutIcon className="text-primary-white w-5 h-5 mr-2" />
                  {toggleSidebar ? (
                    <span className="text-primary-white text-lg">Logout</span>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}

          {/* company Dashboard  */}
          {auth.token && location.pathname.includes("company") && (
            <>
              <ul className="flex flex-col gap-2 px-4 py-6">
                {company_admin.sidebar.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setActiveItem(index);
                        setToggle(!toggleSidebar);
                      }}
                    >
                      <Link to={item.link}>
                        <List
                          isActive={activeItem === index ? true : false}
                          Icon={item.icon}
                          toggleSidebar={toggleSidebar}
                          text={`${toggleSidebar ? `${item.text}` : ""}`}
                        />
                      </Link>
                    </div>
                  );
                })}
                <div className="uppercase p-1 px-4 border-b-[1px] border-primary-white text-primary-white text-lg mt-4">
                  settings
                </div>

                {company_admin.settings.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setActiveItem(index + 5);
                        setToggle(!toggleSidebar);
                      }}
                    >
                      <Link to={item.link}>
                        <List
                          isActive={activeItem === index + 5 ? true : false}
                          Icon={item.icon}
                          toggleSidebar={toggleSidebar}
                          text={`${toggleSidebar ? `${item.text}` : ""}`}
                        />
                      </Link>
                    </div>
                  );
                })}
              </ul>

              <div
                className="flex items-center cursor-pointer mt-auto px-8 pb-2"
                onClick={() => {
                  dispatch(logout());
                  setToggle(!toggleSidebar);
                }}
              >
                <LogoutIcon className="text-primary-white w-5 h-5 mr-2" />
                {toggleSidebar ? (
                  <span className="text-primary-white text-lg">Logout</span>
                ) : (
                  ""
                )}
              </div>
            </>
          )}

          {/* community Dashboard  */}
          {location.pathname.includes("community") &&
            !location.pathname.includes("company") && (
              <>
                <ul className="flex flex-col gap-2 px-4 py-6">
                  {community_admin.sidebar.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setActiveItem(index);
                          setToggle(!toggleSidebar);
                        }}
                      >
                        <Link to={item.link}>
                          <List
                            isActive={activeItem === index ? true : false}
                            Icon={item.icon}
                            toggleSidebar={toggleSidebar}
                            text={`${toggleSidebar ? `${item.text}` : ""}`}
                          />
                        </Link>
                      </div>
                    );
                  })}
                  <div className="uppercase p-1 px-4 mt-4 border-b-[1px] border-primary-white text-primary-white text-md">
                    Community Settings
                  </div>

                  {community_admin.settings.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setActiveItem(index + 5);
                          setToggle(!toggleSidebar);
                        }}
                      >
                        <Link to={item.link}>
                          <List
                            isActive={activeItem === index + 5 ? true : false}
                            Icon={item.icon}
                            toggleSidebar={toggleSidebar}
                            text={`${toggleSidebar ? `${item.text}` : ""}`}
                          />
                        </Link>
                      </div>
                    );
                  })}
                </ul>
                <div
                  className="flex items-center cursor-pointer mt-auto px-8 pb-2"
                  onClick={() => {
                    dispatch(logout());
                    setToggle(!toggleSidebar);
                  }}
                >
                  <LogoutIcon className="text-primary-white w-5 h-5 mr-2" />
                  {toggleSidebar ? (
                    <span className="text-primary-white text-lg">Logout</span>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
}
