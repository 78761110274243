import LabelValueField from "../../components/LabelValueField/LabelValueField";
import { formatTimestamp } from "../../utils/helpers/dateFormat";

function LetterHistoryCard({ letter }) {
  return (
    <div className="relative border-[1px] border-gray shadow-inputShadow rounded-2xl p-4 flex flex-col gap-[0.6rem]">
      <LabelValueField label="Job Name" value={letter.jobDetails?.jobName} />
      <div className="flex gap-[16rem]">
        <LabelValueField label="Level" value={letter.levelDetails?.title} />
        <div className="flex gap-[1.4rem] text-primary-red">
          <span>Attorney :</span>
          <span>{letter.levelDetails?.flagForAttorney ? "Yes" : "No"}</span>
        </div>
        <LabelValueField
          label="Letter Date"
          value={formatTimestamp(letter.createdAt).split("\n")[0]}
        />
      </div>

      {letter.violationsAssociated?.map((violation) => {
        return (
          <div className="border-b-[1px] border-gray px-[1rem] py-[0.7rem] text-secondary-gray">
            {violation.issues}
          </div>
        );
      })}
    </div>
  );
}

export default LetterHistoryCard;
