import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import ViolationCard from "../../components/ViolationCard/ViolationCard";
import Header from "../../components/Header/Header";
import { useParams } from "react-router-dom";
import axios from "../../utils/helpers/axios";

function OpenConcernHistory() {
  const { streetName, streetNumber, ownerToken } = useParams();

  const [openViolations, setOpenViolations] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const getOwnerViewData = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/owner/propertyDetails?streetName=${streetName}&streetNumber=${streetNumber}&ownerAccessToken=${ownerToken}`
      );

      if (data.success) {
        setOpenViolations(data?.propertyDetails?.[0]?.openViolations);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOwnerViewData();
  }, []);

  return (
    <div className="w-full">
      <Header title="Current Open Violations" />

      <div className="overflow-auto flex-1  px-2 py-5 space-y-4">
        {isLoading && (
          <div className="flex flex-1 justify-center">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}
        {!isLoading &&
          openViolations.map((violation) => {
            return <ViolationCard violation={violation} />;
          })}
      </div>
    </div>
  );
}

export default OpenConcernHistory;
