import Modal from "react-responsive-modal";
import { useState } from "react";

import Button from "../Button/Button";

import DeleteIcon from "../../assets/svg/deleteIcon";
import CompanyDashboardDefault from "../../assets/images/companyDashboard_default.png";

export default function PhotoPreviewModal({
  openModel,
  setOpenModel,
  imageData,
  deletePhoto,
  photoHistory,
  showDelete,
}) {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  return (
    <div className="w-[730px]">
      <Modal
        closeOnOverlayClick={true}
        showCloseIcon={false}
        blockScroll={true}
        open={openModel}
        onClose={() => {
          setOpenModel(false);
        }}
        center
      >
        <div className="h-[80vh]">
          <img
            src={imageData.s3PresignedURL || CompanyDashboardDefault}
            alt=""
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
          />
          <div className="absolute flex left-3 bottom-3 gap-4">
            <Button
              innerText={imageData.fileName}
              extraClass="flex justify-center items-center text-primary-teal bg-primary-white py-2 px-4 text-sm rounded-full text-nowrap"
            />
            {showDelete && (
              <Button
                Icon={DeleteIcon}
                iconClass=" w-[1rem] h-[1rem]"
                extraClass="relative flex justify-center items-center text-primary-teal bg-primary-white py-2 px-2 rounded-full border-gray border-[0.5px]"
                isLoading={isLoadingDelete}
                disabled={isLoadingDelete}
                onClick={() =>
                  deletePhoto(photoHistory._id, setIsLoadingDelete)
                }
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
