import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Login from "./Pages/Login/Login";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import Layout from "./components/Layout/Layout";
import Company from "./Pages/Company/Company";
import AddCompany from "./components/AddCompany/AddCompany";

import EmailTemplate from "./Pages/EmailTemplate/EmailTemplate";
import Job from "./components/Job/Job";
import Communities from "./Pages/Communities/Communities";

import ReviewJob from "./Pages/ReviewJob/ReviewJob";
import { login, logout } from "./redux/features/auth-slice";
import {
  login as masterLogin,
  logout as masterLogout,
} from "./redux/features/masterAuthSlice";
import AddCommunity from "./components/AddCommunity/AddCommunity";

import Dashboard from "./Pages/Dashboard/Dashboard";
import CompanyDashboard from "./Pages/CompanyDashboard/CompanyDashboard";
import CommunitiesDashboard from "./Pages/CommunitiesDashboard/CommunitiesDashboard";

import SecurityPermission from "./Pages/SecurityPermission/SecurityPermission";
import SecurityPermissionCompany from "./Pages/SecurityPermission/SecurityPermissionCompany";

import Attornies from "./Pages/Attornies/Attornies";
import AddAttorney from "./components/AddAttorney/AddAttorney";

import ViolationLibrary from "./Pages/ViolationLibrary/ViolationLibrary";
import ViolationLibraryCompany from "./Pages/ViolationLibrary/ViolationLibraryCompany";
import ViolationLibraryCommunity from "./Pages/ViolationLibrary/ViolationLibraryCommunity";
import AddViolation from "./components/AddViolation/AddViolation";
import AddViolationCompany from "./components/AddViolation/AddViolationCompany";
import AddViolationCommunity from "./components/AddViolation/AddViolationCommunity";

import LetterLibrary from "./Pages/LetterLibrary/LetterLibrary";
import LetterLibraryCompany from "./Pages/LetterLibrary/LetterLibraryCompany";
import LetterLibraryCommunity from "./Pages/LetterLibrary/LetterLibraryCommunity";
import AddLetterTemplate from "./components/LetterTemplate/AddLetterTemplate";
import AddLetterTemplateCompany from "./components/LetterTemplate/AddLetterTemplateCompany";
import AddLetterTemplateCommunity from "./components/LetterTemplate/AddLetterTemplateCommunity";

import CompanyProperties from "./Pages/Properties/CompanyProperties";
import CommunityProperties from "./Pages/Properties/CommunityProperties";
import AddProperty from "./components/AddProperty/AddProperty";
import PropertyDetails from "./Pages/PropertyDetails/PropertyDetails";
import ChangeOwnerHistory from "./components/ChangeOwnerHistory/ChangeOwnerHistory";
import OwnersHistory from "./Pages/OwnersHistory/OwnersHistory";
import OpenViolation from "./Pages/OpenViolation/OpenViolation";
import MaintainanceConcernHistory from "./Pages/MaintainanceConcernHistory/MaintainanceConcernHistory";
import PhotoHistory from "./Pages/PhotoHistory/PhotoHistory";
import LetterHistory from "./Pages/LetterHistory/LetterHistory";

import WarnNotesProperty from "./Pages/WarnNotes/WarnNotesProperty";
import AddWarnNotesProperty from "./components/AddWarnNotes/AddWarnNotesProperty";
import WarnNotesCommunity from "./Pages/WarnNotes/WarnNotesCommunity";
import AddWarnNotesCommunity from "./components/AddWarnNotes/AddWarnNotesCommunity";

import ManageUserRole from "./Pages/ManageUserRole/ManageUserRole";
import AddUserRole from "./components/AddUserRole/AddUserRole";

import CompanyJobs from "./Pages/Jobs/CompanyJobs";
import CommunityJobs from "./Pages/Jobs/CommunityJobs";

import User from "./Pages/User/User";
import AddUser from "./components/AddUser/AddUser";
import UserCompany from "./Pages/User/UserCompany";
import AddUserCompany from "./components/AddUser/AddUserCompany";
import UserCommunity from "./Pages/User/UserCommunity";
import AddUserCommunity from "./components/AddUser/AddUserCommunity";

import Profile from "./Pages/Profile/Profile";

import LetterLevels from "./Pages/LetterLevels/LetterLevels";
import AddLetterLevel from "./components/AddLetterLevel/AddLetterLevel";

import Reports from "./Pages/Reports/Reports";
import OwnersView from "./Pages/OwnersView/OwnersView";

import DocumentLibrary from "./Pages/DocumentLibrary/DocumentLibrary";
import AddReferenceTopic from "./components/AddReferenceTopic/AddReferenceTopic";

import Help from "./Pages/Help/Help";
import Article from "./Pages/Help/Article";
import AdminHelp from "./Pages/Help/AdminHelp";
import AdminAddHelpArticle from "./components/Help/AdminAddHelpArticle";
import OpenConcernHistory from "./Pages/OwnersView/OpenConcernHistory";
import OwnerPhotoHistory from "./Pages/OwnersView/PhotoHistory";
import ConcernHistoty from "./Pages/OwnersView/ConcernHistoty";
import OwnerLetterHistory from "./Pages/OwnersView/LetterHistory";

function App() {
  const auth = useSelector((store) => store.auth);
  const adminAuth = useSelector((store) => store.adminAuth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (
      userData &&
      userData.token &&
      userData.expirationDate &&
      new Date(userData.expirationDate) > new Date()
    ) {
      dispatch(
        login({
          token: userData.token,
          user: userData.user,
          expirationDate: userData.expirationDate,
        })
      );
    } else {
      dispatch(logout());
    }
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("adminData"));
    if (
      userData &&
      userData.token &&
      userData.expirationDate &&
      new Date(userData.expirationDate) > new Date()
    ) {
      dispatch(
        masterLogin({
          token: userData.token,
          user: userData.user,
          expirationDate: userData.expirationDate,
        })
      );
    } else {
      dispatch(masterLogout());
    }
    setIsLoading(false);
  }, [dispatch]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="max-w-screen">
      <Layout>
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={
                !auth.token ? (
                  <Login url="/api/auth/login" />
                ) : (
                  <Navigate to="/companyDashboard" replace />
                )
              }
            />
            <Route
              path="/login-admin"
              element={
                !adminAuth.token ? (
                  <Login url="/admin/login" adminLogin={true} />
                ) : (
                  <Navigate to="/dashboard" replace />
                )
              }
            />
            <Route
              path="/forgot-password/:role"
              element={
                !auth.token || !adminAuth.token ? (
                  <ForgotPassword />
                ) : (
                  <Navigate
                    to={adminAuth.token ? "/dashboard" : "/companyDashboard"}
                    replace
                  />
                )
              }
            />

            <Route
              path="/reset-password/:role/:resetToken/:email"
              element={
                !auth.token || !adminAuth.token ? (
                  <ResetPassword />
                ) : (
                  <Navigate
                    to={adminAuth.token ? "/dashboard" : "/companyDashboard"}
                    replace
                  />
                )
              }
            />

            <Route exact path="/owner-view" element={<OwnersView />} />
            <Route
              exact
              path="/owner-view/currentOpenConcerns/:streetName/:streetNumber/:ownerToken"
              element={<OpenConcernHistory />}
            />
            <Route
              exact
              path="/owner-view/photoHistory/:streetName/:streetNumber/:ownerToken"
              element={<OwnerPhotoHistory />}
            />
            <Route
              exact
              path="/owner-view/concernHistory/:streetName/:streetNumber/:ownerToken"
              element={<ConcernHistoty />}
            />
            <Route
              exact
              path="/owner-view/letterHistory/:streetName/:streetNumber/:ownerToken"
              element={<OwnerLetterHistory />}
            />

            {auth && (
              <Route
                element={
                  <ProtectedRoute token={auth?.token} redirectPath="/" />
                }
              >
                {/* Company user */}
                <Route exact path="/user-company" element={<UserCompany />} />
                <Route
                  exact
                  path="/user-company/add"
                  element={<AddUserCompany />}
                />
                <Route
                  exact
                  path="/company/user/:id/edit"
                  element={<AddUserCompany />}
                />
                <Route
                  exact
                  path="/company/manage-role"
                  element={<ManageUserRole />}
                />
                <Route
                  exact
                  path="/company/manage-role/add"
                  element={<AddUserRole />}
                />

                {/* Community Users  */}
                <Route
                  exact
                  path="/community/:id/user"
                  element={<UserCommunity />}
                />
                <Route
                  exact
                  path="/community/:id/user/add"
                  element={<AddUserCommunity />}
                />
                <Route
                  exact
                  path="/community/:id/user/:uid/edit"
                  element={<AddUserCommunity />}
                />

                {/* violation library Company */}
                <Route
                  exact
                  path="/company/violation-library"
                  element={<ViolationLibraryCompany />}
                />
                <Route
                  exact
                  path="/company/violation-library/add"
                  element={<AddViolationCompany />}
                />
                <Route
                  exact
                  path="/company/violation-library/edit/:id"
                  element={<AddViolationCompany />}
                />

                {/* violation library Community */}
                <Route
                  exact
                  path="/community/violation-library/:id"
                  element={<ViolationLibraryCommunity />}
                />
                <Route
                  exact
                  path="/community/violation-library/add/:id"
                  element={<AddViolationCommunity />}
                />
                <Route
                  exact
                  path="/community/:id/violation-library/edit/:vid"
                  element={<AddViolationCommunity />}
                />

                {/* letter template  Company*/}
                <Route
                  exact
                  path="/company/letter-library"
                  element={<LetterLibraryCompany />}
                />
                <Route
                  exact
                  path="/company/letter-template"
                  element={<AddLetterTemplateCompany />}
                />
                <Route
                  exact
                  path="/company/letter-template/edit/:id"
                  element={<AddLetterTemplateCompany />}
                />

                {/* letter Template community*/}
                <Route
                  exact
                  path="/community/letter-library/:id"
                  element={<LetterLibraryCommunity />}
                />
                <Route
                  exact
                  path="/community/letter-template/:id"
                  element={<AddLetterTemplateCommunity />}
                />
                <Route
                  exact
                  path="/community/:id/letter-template/edit/:vid"
                  element={<AddLetterTemplateCommunity />}
                />

                {/* Company property */}
                <Route
                  exact
                  path="/company/properties"
                  element={<CompanyProperties />}
                />

                {/* Community Properties */}
                <Route
                  exact
                  path="/community/:id/properties"
                  element={<CommunityProperties />}
                />

                <Route
                  exact
                  path="/community/:id/property/add"
                  element={<AddProperty />}
                />

                <Route
                  exact
                  path="/community/:id/property-details/:pid"
                  element={<PropertyDetails />}
                />
                <Route
                  exact
                  path="/community/:id/property/:pid/property-details/edit"
                  element={<AddProperty />}
                />
                <Route
                  exact
                  path="/community/:id/property/:pid/owner-details/:oid/edit"
                  element={<AddProperty />}
                />

                <Route
                  exact
                  path="/community/:id/property/:pid/change-ownership"
                  element={<ChangeOwnerHistory />}
                />
                <Route
                  exact
                  path="/community/:id/property/:pid/owner-history"
                  element={<OwnersHistory />}
                />
                <Route
                  exact
                  path="/community/:id/property/:pid/openViolation"
                  element={<OpenViolation />}
                />
                <Route
                  exact
                  path="/community/:id/property/:pid/violation-history"
                  element={<MaintainanceConcernHistory />}
                />
                <Route
                  exact
                  path="/community/:id/property/:pid/photo-history"
                  element={<PhotoHistory />}
                />
                <Route
                  exact
                  path="/community/:id/property/:pid/letter-history"
                  element={<LetterHistory />}
                />

                {/* Warn Notes Property*/}
                <Route
                  exact
                  path="/community/:id/property/:pid/warn-notes"
                  element={<WarnNotesProperty />}
                />
                <Route
                  exact
                  path="/community/:id/property/:pid/warn-notes/add"
                  element={<AddWarnNotesProperty />}
                />
                <Route
                  exact
                  path="/community/:id/property/:pid/warn-notes/:wid/edit"
                  element={<AddWarnNotesProperty />}
                />

                {/* Warn Notes Community */}
                <Route
                  exact
                  path="/community/:id/warn-notes"
                  element={<WarnNotesCommunity />}
                />
                <Route
                  exact
                  path="/community/:id/warn-notes/add"
                  element={<AddWarnNotesCommunity />}
                />

                {/* Community Letter level */}
                <Route
                  exact
                  path="/community/:id/letter-level"
                  element={<LetterLevels />}
                />

                <Route
                  exact
                  path="/community/:id/letter-level/add"
                  element={<AddLetterLevel />}
                />
                <Route
                  exact
                  path="/community/:id/letter-level/edit/:lid"
                  element={<AddLetterLevel />}
                />

                {/* Document Library */}
                <Route
                  exact
                  path="/community/:id/document-library"
                  element={<DocumentLibrary />}
                />
                <Route
                  exact
                  path="/community/:id/quick-reference/add"
                  element={<AddReferenceTopic />}
                />
                <Route
                  exact
                  path="/community/:id/quick-reference/:qid/edit"
                  element={<AddReferenceTopic />}
                />

                {/* email template  */}
                <Route
                  exact
                  path="/email-template"
                  element={<EmailTemplate />}
                />

                {/* company permissions  */}
                <Route
                  exact
                  path="/company/security-permission"
                  element={<SecurityPermissionCompany />}
                />

                <Route
                  exact
                  path="/company/security-permission/:id"
                  element={<SecurityPermissionCompany />}
                />

                {/* company Dashboard  */}
                <Route
                  exact
                  path="/companyDashboard"
                  element={<CompanyDashboard />}
                />

                {/* profile */}
                <Route
                  exact
                  path="/company/user-profile"
                  element={<Profile />}
                />

                {/* Company jobs  */}
                <Route exact path="/company/jobs" element={<CompanyJobs />} />

                {/* Community Jobs */}
                <Route
                  exact
                  path="/community/jobs/:id"
                  element={<CommunityJobs />}
                />
                <Route
                  exact
                  path="/community/:id/job/:jid/job-summary"
                  element={<Job />}
                />
                <Route
                  exact
                  path="/community/:id/job/:jid/review"
                  element={<ReviewJob />}
                />

                {/* communitites  */}
                <Route
                  exact
                  path="/company/communities"
                  element={<Communities />}
                />

                <Route
                  exact
                  path="/company/communities/add"
                  element={<AddCommunity />}
                />

                <Route
                  exact
                  path="/community/:id/edit"
                  element={<AddCommunity />}
                />

                <Route
                  exact
                  path="/company/attornies"
                  element={<Attornies />}
                />

                <Route
                  exact
                  path="/company/add-attornies"
                  element={<AddAttorney />}
                />
                <Route
                  exact
                  path="/company/attorny/:id/edit"
                  element={<AddAttorney />}
                />

                {/*help*/}
                <Route exact path="/company/help" element={<Help />} />
                <Route
                  exact
                  path="/company/help/article"
                  element={<Article />}
                />

                {/* communitites pages  */}
                <Route
                  exact
                  path="/community/dashboard/:id"
                  element={<CommunitiesDashboard />}
                />
              </Route>
            )}

            {adminAuth && (
              <Route
                element={
                  <ProtectedRoute
                    token={adminAuth?.token}
                    redirectPath="/login-admin"
                  />
                }
              >
                {/* dashboard  */}
                <Route exact path="/dashboard" element={<Dashboard />} />

                {/* company  */}
                <Route exact path="/companies" element={<Company />} />

                <Route exact path="/companies/add" element={<AddCompany />} />
                <Route
                  exact
                  path="/companies/edit/:id"
                  element={<AddCompany />}
                />

                {/* Master User  */}
                <Route exact path="/user" element={<User />} />
                <Route exact path="/user/add" element={<AddUser />} />
                <Route exact path="/user/edit/:id" element={<AddUser />} />

                {/* violation library Master */}
                <Route
                  exact
                  path="/violation-library"
                  element={<ViolationLibrary />}
                />
                <Route
                  exact
                  path="/violation-library/add"
                  element={<AddViolation />}
                />
                <Route
                  exact
                  path="/violation-library/edit/:id"
                  element={<AddViolation />}
                />

                {/* letter template Master */}
                <Route
                  exact
                  path="/letter-library"
                  element={<LetterLibrary />}
                />
                <Route
                  exact
                  path="/letter-template"
                  element={<AddLetterTemplate />}
                />
                <Route
                  exact
                  path="/letter-template/edit/:id"
                  element={<AddLetterTemplate />}
                />

                {/* master permissions  */}
                <Route
                  exact
                  path="/security-permission"
                  element={<SecurityPermission />}
                />
                <Route
                  exact
                  path="/security-permission/:id"
                  element={<SecurityPermission />}
                />

                {/* Report */}
                <Route exact path="/report" element={<Reports />} />

                {/* Help */}
                <Route exact path="/help" element={<AdminHelp />} />
                <Route
                  exact
                  path="/help/article/add"
                  element={<AdminAddHelpArticle />}
                />
              </Route>
            )}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </ScrollToTop>
        <Toaster position="bottom-left" reverseOrder={false} />
      </Layout>
    </div>
  );
}

export default App;
