import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import Header from "../../components/Header/Header";
import ConcernHistoryCard from "../../components/ConcernHistoryCard/ConcernHistoryCard";
import axios from "../../utils/helpers/axios";
import Pagination from "../../components/Pagination/Pagination";
import Button from "../../components/Button/Button";

function ConcernHistoty() {
  const { streetName, streetNumber, ownerToken } = useParams();

  const [violationHistories, setViolationHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOwnerViewData = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/owner/propertyDetails?streetName=${streetName}&streetNumber=${streetNumber}&ownerAccessToken=${ownerToken}`
      );

      if (data.success) {
        setViolationHistories(data?.propertyDetails?.[0]?.violationHistory);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOwnerViewData();
  }, []);

  return (
    <div className="w-full flex flex-col">
      <Header
        title="Maintainance Concern History"
        address={`${streetNumber}, ${streetName}`}
      />

      <div className="relative overflow-auto flex-1 flex flex-col gap-[1.4rem] p-[1rem]">
        {isLoading && (
          <div className="flex flex-1 justify-center p-[2rem]">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}
        {!isLoading &&
          violationHistories.map((violation) => {
            return (
              <ConcernHistoryCard violation={violation} showDelete={false} />
            );
          })}
        {!isLoading && violationHistories.length === 0 && (
          <div className="text-primary-gray flex justify-center">
            No Violation History Found
          </div>
        )}
      </div>
    </div>
  );
}

export default ConcernHistoty;
