import { useContext, useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header/Header";
import CustomTooltip from "../../components/Tooltip/Tooltip";
import LabelValueField from "../../components/LabelValueField/LabelValueField";
import Button from "../../components/Button/Button";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import axios from "../../utils/helpers/axios";
import { formatTimestamp } from "../../utils/helpers/dateFormat";

import PropertiesIcon from "../../assets/svg/propertiesIcon";
import ViolationIcon from "../../assets/svg/violationIcon";
import InfoIcon from "../../assets/svg/infoIcon.svg";
import Input from "../../components/Input/Input";
import { ClipLoader } from "react-spinners";

function OwnersView() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const navigate = useNavigate();

  const [ownersData, setOwnerData] = useState({
    streetNumber: "",
    streetName: "",
    ownerAccessToken: "",
  });
  const [ownersViewData, setOwnersViewData] = useState({});
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setOwnerData((prev) => ({ ...prev, [name]: value }));
  };

  const getOwnerViewData = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/owner/propertyDetails?streetName=${ownersData?.streetName}&streetNumber=${ownersData?.streetNumber}&ownerAccessToken=${ownersData?.ownerAccessToken}`
      );

      if (data.success) {
        setOwnersViewData(data?.propertyDetails?.[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div className="w-full">
      <Header title="Owner's View" address="123, Bluff Drive" />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex px-8 pt-4 gap-4"
      >
        {isLoading && (
          <div className="flex justify-center flex-1">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}

        {!isLoading && (
          <>
            <div className="flex flex-col min-w-[52%]">
              <div>
                <div className="flex items-center gap-[0.5rem] text-primary-teal border-b-[1px] border-primary-gray-light ">
                  <PropertiesIcon />
                  <div className="text-lg">Property Address</div>
                </div>
                <div className="flex flex-col gap-[0.4rem] py-[1rem]">
                  <LabelValueField
                    label="Street No."
                    value={ownersViewData?.streetNumber}
                  />
                  <LabelValueField
                    label="Street Name"
                    value={ownersViewData?.streetName}
                  />
                  <LabelValueField
                    label="Address Line 2"
                    value={ownersViewData?.addressLine2}
                  />
                  <div className="flex justify-between">
                    <LabelValueField
                      label="City"
                      value={ownersViewData?.city}
                    />
                    <LabelValueField
                      label="State"
                      value={ownersViewData?.state}
                    />
                    <LabelValueField label="Zip" value={ownersViewData?.zip} />
                  </div>
                  <div className=" flex items-center gap-[1rem]">
                    <div className={"text-sm text-primary-teal font-semibold"}>
                      Tenant Occupied :
                    </div>
                    <input
                      type="checkbox"
                      checked={
                        `${ownersViewData?.tenantOccupied === "yes"}`
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-[0.5rem] text-primary-teal border-b-[1px] border-primary-gray-light">
                  <ViolationIcon />
                  <div className="text-lg">
                    Current Open Maintenance Concerns
                  </div>
                </div>
                <div className="flex flex-col ">
                  {ownersViewData?.openViolations?.map((violation) => {
                    return (
                      <div className="flex flex-col gap-[0.4rem] border-b-[1px] border-primary-gray-light pb-[0.5rem]">
                        <div className="text-primary-black">
                          {violation.violationData.content}
                        </div>
                        <div className="text-primary-gray-light text-sm">
                          <span>{formatTimestamp(violation.openedOn)}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  onClick={() =>
                    navigate(
                      `/owner-view/currentOpenConcerns/${ownersData?.streetName}/${ownersData?.streetNumber}/${ownersData?.ownerAccessToken}`
                    )
                  }
                  className="flex justify-center text-secondary-gray-light my-[1rem] cursor-pointer"
                >
                  View All
                </div>
                <div className="flex gap-[0.5rem]">
                  <Button
                    type="submit"
                    innerText="Photo History"
                    extraClass="shadow-button bg-primary-teal rounded-full text-[0.8rem] uppercase text-primary-white px-[1rem] py-[0.5rem]"
                    onClick={() =>
                      navigate(
                        `/owner-view/photoHistory/${ownersData?.streetName}/${ownersData?.streetNumber}/${ownersData?.ownerAccessToken}`
                      )
                    }
                  />
                  <Button
                    type="submit"
                    innerText="Maintenance Concern History"
                    extraClass="shadow-button bg-primary-teal rounded-full text-[0.8rem] uppercase text-primary-white px-[1rem] py-[0.5rem]"
                    onClick={() =>
                      navigate(
                        `/owner-view/concernHistory/${ownersData?.streetName}/${ownersData?.streetNumber}/${ownersData?.ownerAccessToken}`
                      )
                    }
                  />
                  <Button
                    type="submit"
                    innerText="Letter History"
                    extraClass="shadow-button bg-primary-teal rounded-full text-[0.8rem] uppercase text-primary-white px-[1rem] py-[0.5rem]"
                    onClick={() =>
                      navigate(
                        `/owner-view/letterHistory/${ownersData?.streetName}/${ownersData?.streetNumber}/${ownersData?.ownerAccessToken}`
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center gap-[6px] text-primary-teal font-semibold">
                Notes *
                <CustomTooltip title={"Tooltip"} placement={"right-start"}>
                  <img
                    src={InfoIcon}
                    alt=""
                    className="cursor-pointer h-[1.2rem]"
                  />
                </CustomTooltip>
              </div>
              <div className="flex flex-col grow gap-[1rem]">
                <div className="flex flex-col gap-[0.4rem]">
                  <h3 className="text-secondary-gray">
                    Would you like to leave a note for the community inspector
                    regarding open maintenance concerns?
                  </h3>
                  <p className="text-primary-gray text-[0.8rem]">
                    (These notes are not seen by anyone else within the
                    Association, nor are they monitored. The inspector will see
                    them during the next inspection. For any other
                    comments/concerns, please contact the association directly)
                  </p>
                </div>
                <textarea
                  placeholder="Start Typing..."
                  style={{
                    height: "18rem",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    outline: "1px solid #f6f6f6",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    color: "#666666",
                    padding: "1rem",
                    fontSize: "0.8rem",
                  }}
                />
                <div className="flex justify-end mt-[1.6rem]">
                  <Button
                    type="submit"
                    innerText="Add Note"
                    extraClass="shadow-button bg-primary-teal rounded-full text-[0.8rem] uppercase text-primary-white px-[1rem] py-[0.5rem]"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        closeOnOverlayClick={false}
        showCloseIcon={false}
        blockScroll={true}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        center
      >
        <div className="shadow-inputShadow rounded-xl p-8 flex flex-col gap-y-8 ">
          <Input
            label="Enter your Street Name"
            labelClass="text-primary-teal"
            type="text"
            placeholder="Street Name"
            required={true}
            name={"streetName"}
            value={ownersData?.streetName}
            onChange={handleInput}
            inputFieldClass="px-4"
            outerClass="w-full"
            extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
          />
          <Input
            label="Enter your Street Number"
            labelClass="text-primary-teal"
            type="text"
            placeholder="Street Number"
            required={true}
            name={"streetNumber"}
            value={ownersData?.streetNumber}
            onChange={handleInput}
            inputFieldClass="px-4"
            outerClass="w-full"
            extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
          />
          <Input
            label="Enter owner's token"
            labelClass="text-primary-teal"
            type="text"
            placeholder="Owner's Token"
            required={true}
            name={"ownerAccessToken"}
            value={ownersData?.ownerAccessToken}
            onChange={handleInput}
            inputFieldClass="px-4"
            outerClass="w-full"
            extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
          />
          <div className="flex justify-end w-full text-sm gap-4">
            <Button
              onClick={() => {
                getOwnerViewData();
                setOpen(false);
              }}
              innerText="Save"
              extraClass="shadow-button bg-primary-teal rounded-full text-[0.8rem] uppercase text-primary-white px-[1rem] py-[0.5rem]"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default OwnersView;
